export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Byte: { input: any; output: any };
  Currency: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Duration: { input: any; output: any };
  EmailAddress: { input: any; output: any };
  GUID: { input: any; output: any };
  HSL: { input: any; output: any };
  HSLA: { input: any; output: any };
  HexColorCode: { input: any; output: any };
  Hexadecimal: { input: any; output: any };
  IBAN: { input: any; output: any };
  IPv4: { input: any; output: any };
  IPv6: { input: any; output: any };
  ISBN: { input: any; output: any };
  ISO8601Duration: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  JWT: { input: any; output: any };
  Latitude: { input: any; output: any };
  LocalDate: { input: any; output: any };
  LocalEndTime: { input: any; output: any };
  LocalTime: { input: any; output: any };
  Long: { input: any; output: any };
  Longitude: { input: any; output: any };
  MAC: { input: any; output: any };
  NegativeFloat: { input: any; output: any };
  NegativeInt: { input: any; output: any };
  NonEmptyString: { input: any; output: any };
  NonNegativeFloat: { input: any; output: any };
  NonNegativeInt: { input: any; output: any };
  NonPositiveFloat: { input: any; output: any };
  NonPositiveInt: { input: any; output: any };
  ObjectID: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  Port: { input: any; output: any };
  PositiveFloat: { input: any; output: any };
  PositiveInt: { input: any; output: any };
  PostalCode: { input: any; output: any };
  RGB: { input: any; output: any };
  RGBA: { input: any; output: any };
  SafeInt: { input: any; output: any };
  Time: { input: any; output: any };
  Timestamp: { input: any; output: any };
  URL: { input: any; output: any };
  USCurrency: { input: any; output: any };
  UUID: { input: any; output: any };
  UnsignedFloat: { input: any; output: any };
  UnsignedInt: { input: any; output: any };
  Upload: { input: any; output: any };
  UtcOffset: { input: any; output: any };
  Void: { input: any; output: any };
};

export enum Address_Type {
  Airport = 'AIRPORT',
  BusinessWoutDock = 'BUSINESS_WOUT_DOCK',
  BusinessWDock = 'BUSINESS_W_DOCK',
  Construction = 'CONSTRUCTION',
  Farm = 'FARM',
  Govt = 'GOVT',
  Grocery = 'GROCERY',
  Hotel = 'HOTEL',
  LimitedAccess = 'LIMITED_ACCESS',
  Military = 'MILITARY',
  Religious = 'RELIGIOUS',
  Residential = 'RESIDENTIAL',
  School = 'SCHOOL',
  SelfStorage = 'SELF_STORAGE',
  TradeShow = 'TRADE_SHOW',
}

export type Accessorial = {
  __typename?: 'Accessorial';
  charge: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type AccessorialCharge = {
  charge: Scalars['Float']['input'];
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type AddressSummary = {
  __typename?: 'AddressSummary';
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type AddressWithAccessorial = {
  __typename?: 'AddressWithAccessorial';
  addressType: Address_Type;
  carrierAddedAccessorials?: Maybe<Array<Scalars['String']['output']>>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  endpoint: Endpoint_Type;
  postalCode: Scalars['String']['output'];
  shipmentType: Shipment_Type;
  state: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  streetAddress1?: Maybe<Scalars['String']['output']>;
  useCount: Scalars['Int']['output'];
  userSelectedAccessorials?: Maybe<Array<Scalars['String']['output']>>;
  uuid: Scalars['UUID']['output'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  deactivatedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type AssignSkuInput = {
  /** ISO-8601 compatible string to denote when the SKU is no longer available in warehouse */
  activeEnd?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 compatible string to denote when the SKU is available in warehouse */
  activeStart?: InputMaybe<Scalars['String']['input']>;
  skuId: Scalars['UUID']['input'];
  warehouseId: Scalars['UUID']['input'];
};

export type AssignSkuResponse = MutationResponse & {
  __typename?: 'AssignSkuResponse';
  error?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Sku>;
  warehouse?: Maybe<Warehouse>;
};

export type BalanceCharge = {
  __typename?: 'BalanceCharge';
  amount: Scalars['Float']['output'];
  buyerId?: Maybe<Scalars['String']['output']>;
  chargeDate?: Maybe<Scalars['Date']['output']>;
  curreny?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  invoicePdfLink?: Maybe<Scalars['String']['output']>;
  invoiceToken?: Maybe<Scalars['String']['output']>;
  isAuth?: Maybe<Scalars['Boolean']['output']>;
  isFinanced?: Maybe<Scalars['Boolean']['output']>;
  milestoneId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  paymentMethodType?: Maybe<Scalars['String']['output']>;
  processingFee?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  streetAddress1?: Maybe<Scalars['String']['output']>;
  timeOffset: Scalars['Float']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BookCuttlefishShipmentInput = {
  bookingId: Scalars['String']['input'];
  legacyPayload: Scalars['JSON']['input'];
};

export type BookCuttlefishShipmentResponse = MutationResponse & {
  __typename?: 'BookCuttlefishShipmentResponse';
  error?: Maybe<Scalars['String']['output']>;
  loadId?: Maybe<Scalars['String']['output']>;
};

export type BookShipmentInput = {
  bookingId: Scalars['String']['input'];
};

export type BookShipmentResponse = MutationResponse & {
  __typename?: 'BookShipmentResponse';
  documents?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  loadId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type BookVendorShipmentInput = {
  bookingId: Scalars['UUID']['input'];
};

export type BookVendorShipmentResponse = MutationResponse & {
  __typename?: 'BookVendorShipmentResponse';
  documents?: Maybe<Scalars['JSON']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  loadId?: Maybe<Scalars['String']['output']>;
};

export type Booking = {
  __typename?: 'Booking';
  bolId: Scalars['String']['output'];
  bolPdf: Scalars['String']['output'];
  bookedAt: Scalars['DateTime']['output'];
  bookingInsurance?: Maybe<BookingInsurance>;
  bookingRequest: Scalars['JSON']['output'];
  commericalInvoice: Scalars['String']['output'];
  creditAmount: Scalars['Float']['output'];
  documents?: Maybe<BookingDocuments>;
  id: Scalars['UUID']['output'];
  insurancePremium: Scalars['Float']['output'];
  legacyQuote?: Maybe<LegacyQuote>;
  letterOfInstructions: Scalars['String']['output'];
  packingList: Scalars['String']['output'];
  promocodeDiscount: Scalars['Float']['output'];
  shipmentType: Shipment_Type;
  shippingCost: Scalars['Float']['output'];
  source?: Maybe<Scalars['String']['output']>;
  totalCost: Scalars['Float']['output'];
  txn?: Maybe<GoShipTransaction>;
};

export type BookingDetails = {
  acknowledgeShipmentAccuracy: Scalars['Boolean']['input'];
  insuredValue: Scalars['Float']['input'];
  promoCode: Scalars['String']['input'];
};

export type BookingDocuments = {
  __typename?: 'BookingDocuments';
  billOfLading: Scalars['String']['output'];
  canadianCustomsInvoice?: Maybe<Scalars['String']['output']>;
  certificateOfInsurance?: Maybe<Scalars['String']['output']>;
  commercialInvoice?: Maybe<Scalars['String']['output']>;
  naftaCertificate?: Maybe<Scalars['String']['output']>;
  packingList?: Maybe<Scalars['String']['output']>;
  shippersLetterOfIns?: Maybe<Scalars['String']['output']>;
};

export type BookingInsurance = {
  __typename?: 'BookingInsurance';
  certificateNumber?: Maybe<Scalars['String']['output']>;
  certificatePdfLink?: Maybe<Scalars['String']['output']>;
  falveyId?: Maybe<Scalars['Int']['output']>;
  insuredValue?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  purchased: Scalars['Boolean']['output'];
};

export type BookingLog = {
  __typename?: 'BookingLog';
  amount: Scalars['Float']['output'];
  attempts: Scalars['Int']['output'];
  destinationZip: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  logStatus: Scalars['String']['output'];
  originZip: Scalars['String']['output'];
  pickupDate: Scalars['Date']['output'];
  quotes?: Maybe<Scalars['JSON']['output']>;
  rfq?: Maybe<Scalars['JSON']['output']>;
  selectedQuote?: Maybe<Scalars['JSON']['output']>;
};

export type BookingOrder = {
  __typename?: 'BookingOrder';
  bookingId: Scalars['String']['output'];
  shippingCost?: Maybe<Scalars['Float']['output']>;
  totalCost: Scalars['Float']['output'];
  transactionId: Scalars['String']['output'];
  transactionToken: Scalars['String']['output'];
};

export type BookingRequest = {
  billing?: InputMaybe<FullEndPoint>;
  bookingDetails: BookingDetails;
  commonAccessorials?: InputMaybe<CommonAccessorials>;
  customs?: InputMaybe<Customs>;
  destination: FullEndPoint;
  items: Array<Item>;
  origin: FullEndPoint;
  quote: QuoteInput;
  quoteId: Scalars['String']['input'];
};

export type BookingResponse = {
  __typename?: 'BookingResponse';
  bookingId: Scalars['String']['output'];
  creditAmount?: Maybe<Scalars['Float']['output']>;
  insurancePremium?: Maybe<Scalars['Float']['output']>;
  promoCodeDiscount?: Maybe<Scalars['Float']['output']>;
  shippingCost?: Maybe<Scalars['Float']['output']>;
  totalCost: Scalars['Float']['output'];
  transactionId: Scalars['String']['output'];
  transactionStatus: Scalars['String']['output'];
  transactionToken: Scalars['String']['output'];
};

export type BrokerAddress = {
  brokerAuthorized: Scalars['Boolean']['input'];
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Country;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  timeOffset: Scalars['Float']['input'];
  usePlsBroker: Scalars['Boolean']['input'];
};

export type Buyer = {
  __typename?: 'Buyer';
  billingAddress?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  qualificationStatus?: Maybe<Scalars['String']['output']>;
};

export enum Country {
  Can = 'CAN',
  Usa = 'USA',
}

export enum Customs_Terms_Of_Sale {
  Cash = 'CASH',
  Collect = 'COLLECT',
  LessPercentDiscount = 'LESS_PERCENT_DISCOUNT',
  Net_30 = 'NET_30',
  Other = 'OTHER',
  PaymentInAdvance = 'PAYMENT_IN_ADVANCE',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CalculateCostInput = {
  costBreakdown: CostCalculateInput;
};

export type CalculateCostResponse = MutationResponse & {
  __typename?: 'CalculateCostResponse';
  costBreakdown?: Maybe<CostBreakdown>;
  error?: Maybe<Scalars['String']['output']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  carrierName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  reliabilityScore: Scalars['Float']['output'];
  scacCode: Scalars['String']['output'];
};

export type CarrierInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  newLiability: Scalars['Float']['input'];
  reliabilityScore?: InputMaybe<Scalars['Float']['input']>;
  scac?: InputMaybe<Scalars['String']['input']>;
  usedLiability: Scalars['Float']['input'];
};

export type CarrierSummary = {
  __typename?: 'CarrierSummary';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type ChangePasswordResponse = MutationResponse & {
  __typename?: 'ChangePasswordResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CommonAccessorials = {
  chains?: InputMaybe<Scalars['Boolean']['input']>;
  coilRacks?: InputMaybe<Scalars['Boolean']['input']>;
  edgeProtectors?: InputMaybe<Scalars['Boolean']['input']>;
  loadBars?: InputMaybe<Scalars['Boolean']['input']>;
  loadLocks?: InputMaybe<Scalars['Boolean']['input']>;
  noFreeze?: InputMaybe<Scalars['Boolean']['input']>;
  ppe?: InputMaybe<Scalars['Boolean']['input']>;
  straps?: InputMaybe<Scalars['Boolean']['input']>;
  tarps?: InputMaybe<Scalars['Boolean']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
};

export type CostBreakdown = {
  __typename?: 'CostBreakdown';
  cost: Scalars['Float']['output'];
  creditAmount: Scalars['Float']['output'];
  finalCost: Scalars['Float']['output'];
  premium?: Maybe<Scalars['Float']['output']>;
  promoCodeDiscount: Scalars['Float']['output'];
};

export type CostBreakdownInput = {
  cost: Scalars['Float']['input'];
  creditAmount: Scalars['Float']['input'];
  finalCost: Scalars['Float']['input'];
  premium: Scalars['Float']['input'];
  promoCodeDiscount: Scalars['Float']['input'];
};

export type CostCalculateInput = {
  commodityValue: Scalars['Float']['input'];
  cost: Scalars['Float']['input'];
  creditAmount?: InputMaybe<Scalars['Float']['input']>;
  insurance: Scalars['Boolean']['input'];
  loadType: Shipment_Type;
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateApiKeyResponse = MutationResponse & {
  __typename?: 'CreateApiKeyResponse';
  apiKey?: Maybe<NewApiKey>;
  error?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type CreateBookingLogInput = {
  amount: Scalars['Float']['input'];
  destinationZip: Scalars['String']['input'];
  originZip: Scalars['String']['input'];
  pickupDate: Scalars['Date']['input'];
  quoteLogUuid?: InputMaybe<Scalars['String']['input']>;
  quotes?: InputMaybe<Scalars['JSON']['input']>;
  rfq?: InputMaybe<Scalars['JSON']['input']>;
  selectedQuote?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateBookingLogResponse = MutationResponse & {
  __typename?: 'CreateBookingLogResponse';
  bookingLog?: Maybe<BookingLog>;
  error?: Maybe<Scalars['String']['output']>;
};

export type CreateFailedTransactionInput = {
  amount: Scalars['Float']['input'];
  city: Scalars['String']['input'];
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  ip: Scalars['String']['input'];
  state: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};

export type CreateFailedTransactionResponse = MutationResponse & {
  __typename?: 'CreateFailedTransactionResponse';
  attempt: Scalars['Int']['output'];
  blocked: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
};

export type CreateMerchantResponse = MutationResponse & {
  __typename?: 'CreateMerchantResponse';
  error?: Maybe<Scalars['String']['output']>;
  merchant?: Maybe<Merchant>;
};

export type CreateReferralCodeInput = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactUuid?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReferralCodeResponse = MutationResponse & {
  __typename?: 'CreateReferralCodeResponse';
  error?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<ReferralCode>;
};

export type CreateReferralCodesFromContactsInput = {
  contactUuids: Array<Scalars['String']['input']>;
};

export type CreateReferralCodesFromContactsResponse = MutationResponse & {
  __typename?: 'CreateReferralCodesFromContactsResponse';
  error?: Maybe<Scalars['String']['output']>;
  referralCodes: Array<ReferralCode>;
};

export type CreateShipmentAddressInput = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
};

export type CreateShipmentAddressResponse = MutationResponse & {
  __typename?: 'CreateShipmentAddressResponse';
  error?: Maybe<Scalars['String']['output']>;
  shipmentAddress?: Maybe<ShipmentAddress>;
};

export type CreateShipmentContactInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateShipmentContactResponse = MutationResponse & {
  __typename?: 'CreateShipmentContactResponse';
  error?: Maybe<Scalars['String']['output']>;
  shipmentContact?: Maybe<ShipmentContact>;
};

export type CreateSkuBulkInput = {
  skus: Array<SkuInput>;
};

export type CreateSkuBulkResponse = MutationResponse & {
  __typename?: 'CreateSkuBulkResponse';
  error?: Maybe<Scalars['String']['output']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
};

export type CreateSkuInput = {
  sku: SkuInput;
};

export type CreateSkuResponse = MutationResponse & {
  __typename?: 'CreateSkuResponse';
  error?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Sku>;
};

export type CreateTransactionDetailInput = {
  bolId: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
  upchargeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTransactionDetailResponse = MutationResponse & {
  __typename?: 'CreateTransactionDetailResponse';
  error?: Maybe<Scalars['String']['output']>;
  transactionDetail?: Maybe<TransactionDetail>;
};

export type CreateUpchargeTransactionInput = {
  amount: Scalars['Float']['input'];
  upchargeId: Scalars['String']['input'];
};

export type CreateUpchargeTransactionResponse = MutationResponse & {
  __typename?: 'CreateUpchargeTransactionResponse';
  error?: Maybe<Scalars['String']['output']>;
  transactionCreateResponse?: Maybe<TransactionCreateResponse>;
};

export type CreateWarehouseInput = {
  addressName?: InputMaybe<Scalars['String']['input']>;
  addressPrimary: Scalars['String']['input'];
  addressSecondary?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  insidePickup?: InputMaybe<Scalars['Boolean']['input']>;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  needsLiftgate?: InputMaybe<Scalars['Boolean']['input']>;
  needsReservation?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  timeoffset: Scalars['Float']['input'];
  zipCode: Scalars['String']['input'];
};

export type CreateWarehouseResponse = MutationResponse & {
  __typename?: 'CreateWarehouseResponse';
  error?: Maybe<Scalars['String']['output']>;
  warehouse?: Maybe<Warehouse>;
};

export type Customs = {
  broker: BrokerAddress;
  createCustomsInvoice: Scalars['Boolean']['input'];
  purchaser: CustomsAddress;
  termsOfSale: Scalars['String']['input'];
  vendor: CustomsAddress;
};

export type CustomsAddress = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Country;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  timeOffset: Scalars['Float']['input'];
  useDelivery: Scalars['Boolean']['input'];
  usePickup: Scalars['Boolean']['input'];
};

export enum Distance_Uom {
  Cm = 'cm',
  In = 'in',
}

export type DeactivateApiKeyResponse = MutationResponse & {
  __typename?: 'DeactivateApiKeyResponse';
  apiKey?: Maybe<ApiKey>;
  error?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export enum Endpoint_Type {
  Destination = 'DESTINATION',
  Origin = 'ORIGIN',
}

export type EditSkuInput = {
  id: Scalars['UUID']['input'];
  sku: SkuInput;
};

export type EditSkuResponse = MutationResponse & {
  __typename?: 'EditSkuResponse';
  error?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Sku>;
};

export type EditWarehouseInput = {
  addressName?: InputMaybe<Scalars['String']['input']>;
  addressPrimary?: InputMaybe<Scalars['String']['input']>;
  addressSecondary?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  insidePickup?: InputMaybe<Scalars['Boolean']['input']>;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  needsLiftgate?: InputMaybe<Scalars['Boolean']['input']>;
  needsReservation?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  timeoffset: Scalars['Float']['input'];
  warehouseId: Scalars['UUID']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type EditWarehouseResponse = MutationResponse & {
  __typename?: 'EditWarehouseResponse';
  error?: Maybe<Scalars['String']['output']>;
  warehouse?: Maybe<Warehouse>;
};

export type EditWarehouseSkuInput = {
  activeEnd?: InputMaybe<Scalars['String']['input']>;
  activeStart?: InputMaybe<Scalars['String']['input']>;
  warehouseSkuId: Scalars['UUID']['input'];
};

export type EditWarehouseSkuResponse = MutationResponse & {
  __typename?: 'EditWarehouseSkuResponse';
  error?: Maybe<Scalars['String']['output']>;
  warehouseSku?: Maybe<WarehouseSku>;
};

export type EndPointAccessorials = {
  appointment?: InputMaybe<Scalars['Boolean']['input']>;
  driverAssist?: InputMaybe<Scalars['Boolean']['input']>;
  inside?: InputMaybe<Scalars['Boolean']['input']>;
  liftgate?: InputMaybe<Scalars['Boolean']['input']>;
  noFreeze?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndPointAccessorialsInput = {
  appointment?: InputMaybe<Scalars['Boolean']['input']>;
  driverAssist?: InputMaybe<Scalars['Boolean']['input']>;
  inside?: InputMaybe<Scalars['Boolean']['input']>;
  liftgate?: InputMaybe<Scalars['Boolean']['input']>;
  noFreeze?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EndPointSummary = {
  __typename?: 'EndPointSummary';
  address: AddressSummary;
  date: Scalars['Date']['output'];
};

export enum Freight_Class {
  Class_50 = 'CLASS_50',
  Class_55 = 'CLASS_55',
  Class_60 = 'CLASS_60',
  Class_65 = 'CLASS_65',
  Class_70 = 'CLASS_70',
  Class_77_5 = 'CLASS_77_5',
  Class_85 = 'CLASS_85',
  Class_92_5 = 'CLASS_92_5',
  Class_100 = 'CLASS_100',
  Class_110 = 'CLASS_110',
  Class_125 = 'CLASS_125',
  Class_150 = 'CLASS_150',
  Class_175 = 'CLASS_175',
  Class_200 = 'CLASS_200',
  Class_250 = 'CLASS_250',
  Class_300 = 'CLASS_300',
  Class_400 = 'CLASS_400',
  Class_500 = 'CLASS_500',
}

export enum Ftl_Accessorial {
  Chains = 'CHAINS',
  Coil = 'COIL',
  Det = 'DET',
  Drasst = 'DRASST',
  Edgepro = 'EDGEPRO',
  Lfc = 'LFC',
  Lft = 'LFT',
  Loadbars = 'LOADBARS',
  Loadlocks = 'LOADLOCKS',
  Loadstraps = 'LOADSTRAPS',
  Pfz = 'PFZ',
  Ppe = 'PPE',
  Tarp = 'TARP',
  Unloaddel = 'UNLOADDEL',
}

export type FtlEndpoint = {
  city: Scalars['String']['input'];
  country: Country;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  postalCode: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  timeZone: Scalars['Float']['input'];
};

export type FtlQuote = {
  __typename?: 'FtlQuote';
  baseRate: Scalars['Float']['output'];
  cost: Scalars['Float']['output'];
  deliveryDate: Scalars['String']['output'];
  ftlAccessorialCharges?: Maybe<Array<Accessorial>>;
  guid: Scalars['String']['output'];
  quoteDate: Scalars['String']['output'];
  shipmentType: Scalars['String']['output'];
  transitTime: Scalars['Int']['output'];
  truckType: Truck_Type;
};

export type FtlRfqInput = {
  accessorials?: InputMaybe<Array<Ftl_Accessorial>>;
  destination: FtlEndpoint;
  origin: FtlEndpoint;
  pickupDate: Scalars['String']['input'];
  truckType: Truck_Type;
};

export type FullAddress = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Country;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  timeOffset: Scalars['Float']['input'];
};

export type FullEndPoint = {
  accessorials: EndPointAccessorials;
  address: FullAddress;
  carrierAddedAccessorials?: InputMaybe<Array<Scalars['String']['input']>>;
  date: Scalars['DateTime']['input'];
  endTime: Scalars['DateTime']['input'];
  instructions: Scalars['String']['input'];
  saveToAddressBook: Scalars['Boolean']['input'];
  startTime: Scalars['DateTime']['input'];
  type: Address_Type;
};

export type GoShipTransaction = {
  __typename?: 'GoShipTransaction';
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export enum Hazmat_Class {
  Hmc_1 = 'HMC_1',
  Hmc_1_1 = 'HMC_1_1',
  Hmc_1_2 = 'HMC_1_2',
  Hmc_1_3 = 'HMC_1_3',
  Hmc_1_4 = 'HMC_1_4',
  Hmc_1_5 = 'HMC_1_5',
  Hmc_1_6 = 'HMC_1_6',
  Hmc_2 = 'HMC_2',
  Hmc_2_1 = 'HMC_2_1',
  Hmc_2_2 = 'HMC_2_2',
  Hmc_2_3 = 'HMC_2_3',
  Hmc_3 = 'HMC_3',
  Hmc_4 = 'HMC_4',
  Hmc_4_1 = 'HMC_4_1',
  Hmc_4_2 = 'HMC_4_2',
  Hmc_4_3 = 'HMC_4_3',
  Hmc_5 = 'HMC_5',
  Hmc_5_1 = 'HMC_5_1',
  Hmc_5_2 = 'HMC_5_2',
  Hmc_6 = 'HMC_6',
  Hmc_6_1 = 'HMC_6_1',
  Hmc_7 = 'HMC_7',
  Hmc_8 = 'HMC_8',
  Hmc_9 = 'HMC_9',
}

export type HazMatDetails = {
  __typename?: 'HazMatDetails';
  contractNumber: Scalars['String']['output'];
  emergencyContactCompany: Scalars['String']['output'];
  emergencyContactPhone: Scalars['String']['output'];
  hazmatClass: Hazmat_Class;
  instructions: Scalars['String']['output'];
  packGroupNumber: Pack_Group_Number;
  reportableQty: Scalars['Boolean']['output'];
  unNumber: Scalars['String']['output'];
};

export type HazMatDetailsInput = {
  contractNumber: Scalars['String']['input'];
  emergencyContactCompany: Scalars['String']['input'];
  emergencyContactPhone: Scalars['String']['input'];
  hazmatClass: Hazmat_Class;
  instructions: Scalars['String']['input'];
  packGroupNumber: Pack_Group_Number;
  reportableQty: Scalars['Boolean']['input'];
  unNumber: Scalars['String']['input'];
};

export type HazmatDetailsInput = {
  contractNumber: Scalars['String']['input'];
  emergencyContactCompany: Scalars['String']['input'];
  emergencyContactPhone: Scalars['String']['input'];
  hazmatClass: Scalars['String']['input'];
  instructions: Scalars['String']['input'];
  packGroupNumber: Scalars['String']['input'];
  reportableQty: Scalars['Boolean']['input'];
  unNumber: Scalars['String']['input'];
};

export enum Item_Condition {
  New = 'NEW',
  Used = 'USED',
}

export type Item = {
  acceptOverdimension?: InputMaybe<Scalars['Boolean']['input']>;
  acceptOverdimensionDate?: InputMaybe<Scalars['DateTime']['input']>;
  countryOfOrigin?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  dimWeightLbs?: InputMaybe<Scalars['Float']['input']>;
  freightClass: Freight_Class;
  hazardous: Scalars['Boolean']['input'];
  hazmatDetails?: InputMaybe<HazmatDetailsInput>;
  height: Scalars['Float']['input'];
  itemCondition: Item_Condition;
  length: Scalars['Float']['input'];
  packaging: Packaging_Types_Short;
  pieces?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  sizeUoM: Distance_Uom;
  stackable: Scalars['Boolean']['input'];
  tariff?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
  weightUoM: Weight_Uom;
  width: Scalars['Float']['input'];
};

export enum Log_Status {
  Booked = 'booked',
  BookingFailure = 'booking_failure',
  InProgress = 'in_progress',
  PaymentFailure = 'payment_failure',
}

export type LegacyBooking = {
  __typename?: 'LegacyBooking';
  carrier?: Maybe<CarrierSummary>;
  carrierPhoneNumber?: Maybe<Scalars['String']['output']>;
  destination: EndPointSummary;
  id: Scalars['String']['output'];
  insured?: Maybe<Scalars['Boolean']['output']>;
  loadStatus: Scalars['String']['output'];
  origin: EndPointSummary;
  scacCode?: Maybe<Scalars['String']['output']>;
  shipmentType: Shipment_Type;
  token?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  txnId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LegacyCarrier = {
  __typename?: 'LegacyCarrier';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  scacCode?: Maybe<Scalars['String']['output']>;
};

export type LegacyQuote = {
  __typename?: 'LegacyQuote';
  guid: Scalars['String']['output'];
  pickupDate: Scalars['Date']['output'];
  quote?: Maybe<Quote>;
  rfq?: Maybe<Scalars['JSON']['output']>;
  truckType?: Maybe<Truck_Type>;
};

export type Line = {
  __typename?: 'Line';
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
  shippingPrice: Scalars['Float']['output'];
  tax: Scalars['Float']['output'];
  vendorId?: Maybe<Scalars['String']['output']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  id?: Maybe<Scalars['Float']['output']>;
  itemType?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  productSku?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  tax?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  variationId?: Maybe<Scalars['String']['output']>;
};

export type LtlEndpoint = {
  accessorials?: InputMaybe<EndPointAccessorialsInput>;
  addressType?: InputMaybe<Address_Type>;
  carrierAddedAccessorials?: InputMaybe<Array<Scalars['String']['input']>>;
  city: Scalars['String']['input'];
  country: Country;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  postalCode: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  timeZone: Scalars['Float']['input'];
};

export type LtlQuote = {
  __typename?: 'LtlQuote';
  baseRate: Scalars['Float']['output'];
  carrier: QuoteCarrier;
  cost: Scalars['Float']['output'];
  deliveryDate: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  ltlAccessorialCharges?: Maybe<Array<Accessorial>>;
  proposal: Proposal;
  shipmentType: Scalars['String']['output'];
  transitTime: Scalars['Int']['output'];
};

export type LtlRfqInput = {
  destination: LtlEndpoint;
  gaCid?: InputMaybe<Scalars['String']['input']>;
  items: Array<RfqItemInput>;
  origin: LtlEndpoint;
  pickupDate: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  fetchAndStoreshopifyProducts: ProductSaveResponse;
  id: Scalars['String']['output'];
  isCarrierServiceInstalled?: Maybe<Scalars['Boolean']['output']>;
  ordersBooked: Array<Order>;
  ordersThatNeedBooking: Array<Order>;
  products?: Maybe<Array<ShopifyProduct>>;
  restrictedCarriers?: Maybe<Array<Scalars['String']['output']>>;
  shopifyProducts: ProductResponse;
  shopifyShopName?: Maybe<Scalars['String']['output']>;
  skus: Array<Sku>;
  warehouseSkus: Array<WarehouseSku>;
  warehouses: Array<Warehouse>;
};

export type MerchantShopifyProductsArgs = {
  limit: Scalars['Int']['input'];
  next: Scalars['String']['input'];
  prev: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  assignSku?: Maybe<AssignSkuResponse>;
  bookCuttlefishShipment?: Maybe<BookCuttlefishShipmentResponse>;
  bookShipment?: Maybe<BookShipmentResponse>;
  bookVendorShipment?: Maybe<BookVendorShipmentResponse>;
  calculateCost?: Maybe<CalculateCostResponse>;
  changePassword: ChangePasswordResponse;
  createApiKey: CreateApiKeyResponse;
  createBookingLog?: Maybe<CreateBookingLogResponse>;
  createFailedTransaction?: Maybe<CreateFailedTransactionResponse>;
  createMerchant?: Maybe<CreateMerchantResponse>;
  createReferralCode?: Maybe<CreateReferralCodeResponse>;
  createReferralCodesFromContacts?: Maybe<CreateReferralCodesFromContactsResponse>;
  createShipmentAddress?: Maybe<CreateShipmentAddressResponse>;
  createShipmentContact?: Maybe<CreateShipmentContactResponse>;
  createSku?: Maybe<CreateSkuResponse>;
  createSkuBulk?: Maybe<CreateSkuBulkResponse>;
  createTransactionDetail?: Maybe<CreateTransactionDetailResponse>;
  createUpchargeTransaction?: Maybe<CreateUpchargeTransactionResponse>;
  createWarehouse?: Maybe<CreateWarehouseResponse>;
  deactivateApiKey: DeactivateApiKeyResponse;
  editSku?: Maybe<EditSkuResponse>;
  editWarehouse?: Maybe<EditWarehouseResponse>;
  editWarehouseSku?: Maybe<EditWarehouseSkuResponse>;
  prepareBookingOrder?: Maybe<PrepareBookingOrderResponse>;
  prepareCuttlefishBooking?: Maybe<PrepareCuttlefishBookingResponse>;
  prepareVendorBooking?: Maybe<PrepareVendorBookingResponse>;
  refetchQuote?: Maybe<RefetchQuoteResponse>;
  removeMerchant?: Maybe<RemoveMerchantResponse>;
  removeSku?: Maybe<RemoveSkuResponse>;
  removeVendorOrder?: Maybe<RemoveVendorOrderResponse>;
  removeWarehouse?: Maybe<RemoveWarehouseResponse>;
  unassignSku?: Maybe<UnassignSkuResponse>;
  updateBookingLog?: Maybe<UpdateBookingLogResponse>;
  updateMerchantCarrier?: Maybe<UpdateMerchantCarriersResponse>;
  updateShipmentAddress?: Maybe<UpdateShipmentAddressResponse>;
  updateShipmentContact?: Maybe<UpdateShipmentContactResponse>;
};

export type MutationAssignSkuArgs = {
  input?: InputMaybe<AssignSkuInput>;
};

export type MutationBookCuttlefishShipmentArgs = {
  input?: InputMaybe<BookCuttlefishShipmentInput>;
};

export type MutationBookShipmentArgs = {
  input?: InputMaybe<BookShipmentInput>;
};

export type MutationBookVendorShipmentArgs = {
  input?: InputMaybe<BookVendorShipmentInput>;
};

export type MutationCalculateCostArgs = {
  input?: InputMaybe<CalculateCostInput>;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCreateApiKeyArgs = {
  name: Scalars['String']['input'];
};

export type MutationCreateBookingLogArgs = {
  input?: InputMaybe<CreateBookingLogInput>;
};

export type MutationCreateFailedTransactionArgs = {
  input?: InputMaybe<CreateFailedTransactionInput>;
};

export type MutationCreateReferralCodeArgs = {
  input?: InputMaybe<CreateReferralCodeInput>;
};

export type MutationCreateReferralCodesFromContactsArgs = {
  input?: InputMaybe<CreateReferralCodesFromContactsInput>;
};

export type MutationCreateShipmentAddressArgs = {
  input?: InputMaybe<CreateShipmentAddressInput>;
};

export type MutationCreateShipmentContactArgs = {
  input?: InputMaybe<CreateShipmentContactInput>;
};

export type MutationCreateSkuArgs = {
  input?: InputMaybe<CreateSkuInput>;
};

export type MutationCreateSkuBulkArgs = {
  input?: InputMaybe<CreateSkuBulkInput>;
};

export type MutationCreateTransactionDetailArgs = {
  input?: InputMaybe<CreateTransactionDetailInput>;
};

export type MutationCreateUpchargeTransactionArgs = {
  input?: InputMaybe<CreateUpchargeTransactionInput>;
};

export type MutationCreateWarehouseArgs = {
  input?: InputMaybe<CreateWarehouseInput>;
};

export type MutationDeactivateApiKeyArgs = {
  id: Scalars['String']['input'];
};

export type MutationEditSkuArgs = {
  input?: InputMaybe<EditSkuInput>;
};

export type MutationEditWarehouseArgs = {
  input?: InputMaybe<EditWarehouseInput>;
};

export type MutationEditWarehouseSkuArgs = {
  input?: InputMaybe<EditWarehouseSkuInput>;
};

export type MutationPrepareBookingOrderArgs = {
  input?: InputMaybe<PrepareBookingOrderInput>;
};

export type MutationPrepareCuttlefishBookingArgs = {
  input?: InputMaybe<PrepareCuttlefishBookingInput>;
};

export type MutationPrepareVendorBookingArgs = {
  input?: InputMaybe<PrepareVendorBookingInput>;
};

export type MutationRefetchQuoteArgs = {
  input?: InputMaybe<RefetchQuoteInput>;
};

export type MutationRemoveSkuArgs = {
  input?: InputMaybe<RemoveSkuInput>;
};

export type MutationRemoveVendorOrderArgs = {
  input?: InputMaybe<RemoveVendorOrderInput>;
};

export type MutationRemoveWarehouseArgs = {
  input?: InputMaybe<RemoveWarehouseInput>;
};

export type MutationUnassignSkuArgs = {
  input?: InputMaybe<UnassignSkuInput>;
};

export type MutationUpdateBookingLogArgs = {
  input?: InputMaybe<UpdateBookingLogInput>;
};

export type MutationUpdateMerchantCarrierArgs = {
  input?: InputMaybe<UpdateMerchantCarriersInput>;
};

export type MutationUpdateShipmentAddressArgs = {
  input?: InputMaybe<UpdateShipmentAddressInput>;
};

export type MutationUpdateShipmentContactArgs = {
  input?: InputMaybe<UpdateShipmentContactInput>;
};

export type MutationResponse = {
  error?: Maybe<Scalars['String']['output']>;
};

export type NewApiKey = {
  __typename?: 'NewApiKey';
  deactivatedAt?: Maybe<Scalars['Date']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime']['output'];
  finalCost: Scalars['Float']['output'];
  hasBooked: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  merchant: Merchant;
  needsBooking: Scalars['Boolean']['output'];
  orderNumber?: Maybe<Scalars['String']['output']>;
  orderQuote?: Maybe<Array<Maybe<OrderQuote>>>;
  shopifyPayload: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderQuote = {
  __typename?: 'OrderQuote';
  booked: Scalars['Boolean']['output'];
  booking?: Maybe<Booking>;
  bookingId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  legacyQuote: LegacyQuote;
  order: Order;
  quoteItems?: Maybe<Array<Maybe<QuoteItem>>>;
  refetchedLegacyQuote?: Maybe<LegacyQuote>;
  updatedAt: Scalars['DateTime']['output'];
  warehouse: Warehouse;
};

export enum Packaging_Types {
  Bags = 'BAGS',
  Bales = 'BALES',
  Barrels = 'BARRELS',
  BasketsHamper = 'BASKETS_HAMPER',
  Bottle = 'BOTTLE',
  Boxes = 'BOXES',
  Buckets = 'BUCKETS',
  Bundles = 'BUNDLES',
  Can = 'CAN',
  Carboy = 'CARBOY',
  Cartons = 'CARTONS',
  Cases = 'CASES',
  Chest = 'CHEST',
  Coils = 'COILS',
  Crates = 'CRATES',
  Cylinders = 'CYLINDERS',
  Drums = 'DRUMS',
  Envelopes = 'ENVELOPES',
  Firkin = 'FIRKIN',
  GaylordBoxes = 'GAYLORD_BOXES',
  Hogshead = 'HOGSHEAD',
  Keg = 'KEG',
  Kits = 'KITS',
  Packages = 'PACKAGES',
  Pails = 'PAILS',
  Pallet = 'PALLET',
  Pieces = 'PIECES',
  Pipe = 'PIPE',
  Rack = 'RACK',
  Reels = 'REELS',
  Rolls = 'ROLLS',
  Skids = 'SKIDS',
  Slipsheet = 'SLIPSHEET',
  Tanks = 'TANKS',
  ToteBins = 'TOTE_BINS',
  Trailer = 'TRAILER',
  Trunk = 'TRUNK',
  Tubes = 'TUBES',
}

export enum Packaging_Types_Short {
  Bag = 'BAG',
  Bal = 'BAL',
  Bbl = 'BBL',
  Bdl = 'BDL',
  Bot = 'BOT',
  Box = 'BOX',
  Bsk = 'BSK',
  Bxt = 'BXT',
  Can = 'CAN',
  Cas = 'CAS',
  Cbc = 'CBC',
  Cby = 'CBY',
  Chs = 'CHS',
  Col = 'COL',
  Crt = 'CRT',
  Ctn = 'CTN',
  Cyl = 'CYL',
  Drm = 'DRM',
  Env = 'ENV',
  Fir = 'FIR',
  Hgh = 'HGH',
  Keg = 'KEG',
  Kit = 'KIT',
  Pal = 'PAL',
  Pcs = 'PCS',
  Pkg = 'PKG',
  Pln = 'PLN',
  Plt = 'PLT',
  Rck = 'RCK',
  Rel = 'REL',
  Rol = 'ROL',
  Skd = 'SKD',
  Slp = 'SLP',
  Tbe = 'TBE',
  Tbn = 'TBN',
  Tld = 'TLD',
  Tnk = 'TNK',
  Trk = 'TRK',
}

export enum Pack_Group_Number {
  PgnI = 'PGN_I',
  PgnIi = 'PGN_II',
  PgnIii = 'PGN_III',
  PgnNa = 'PGN_NA',
}

export type Plan = {
  __typename?: 'Plan';
  chargeDate?: Maybe<Scalars['String']['output']>;
  milestones?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  recurringPayment?: Maybe<Scalars['String']['output']>;
};

export type PrepareBookingOrderInput = {
  orderQuoteId: Scalars['Int']['input'];
  pickupDate?: InputMaybe<Scalars['String']['input']>;
};

export type PrepareBookingOrderResponse = MutationResponse & {
  __typename?: 'PrepareBookingOrderResponse';
  booking?: Maybe<BookingOrder>;
  error?: Maybe<Scalars['String']['output']>;
};

export type PrepareCuttlefishBookingInput = {
  bookingLogId: Scalars['String']['input'];
  bookingRequest: BookingRequest;
  costBreakdown: CostBreakdownInput;
};

export type PrepareCuttlefishBookingResponse = MutationResponse & {
  __typename?: 'PrepareCuttlefishBookingResponse';
  booking?: Maybe<BookingResponse>;
  error?: Maybe<Scalars['String']['output']>;
};

export type PrepareVendorBookingInput = {
  pickupDate?: InputMaybe<Scalars['String']['input']>;
  vendorOrderId: Scalars['UUID']['input'];
};

export type PrepareVendorBookingResponse = MutationResponse & {
  __typename?: 'PrepareVendorBookingResponse';
  booking?: Maybe<BookingOrder>;
  error?: Maybe<Scalars['String']['output']>;
};

export type PricingResult = {
  __typename?: 'PricingResult';
  accessorials?: Maybe<Array<QuoteAccessorial>>;
  carrierFinalLinehaul: Scalars['Float']['output'];
  carrierFuelSurcharge: Scalars['Float']['output'];
  carrierName: Scalars['String']['output'];
  carrierOrgId: Scalars['Int']['output'];
  carrierQuoteNumber?: Maybe<Scalars['String']['output']>;
  currencyCode: Scalars['String']['output'];
  newProdLiability: Scalars['Float']['output'];
  pricingType: Scalars['String']['output'];
  profileId?: Maybe<Scalars['Int']['output']>;
  scac: Scalars['String']['output'];
  serviceType?: Maybe<Scalars['String']['output']>;
  shipperFinalLinehaul: Scalars['Float']['output'];
  shipperFuelSurcharge: Scalars['Float']['output'];
  totalMiles: Scalars['Float']['output'];
  totalShipperCost: Scalars['Float']['output'];
  transitTime: Scalars['Float']['output'];
  usedProdLiability: Scalars['Float']['output'];
};

export type Product = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weight_unit?: Maybe<Scalars['String']['output']>;
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  error?: Maybe<Scalars['String']['output']>;
  next: Scalars['String']['output'];
  previous: Scalars['String']['output'];
  products?: Maybe<Array<Product>>;
};

export type ProductSaveResponse = {
  __typename?: 'ProductSaveResponse';
  error?: Maybe<Scalars['String']['output']>;
  updatedCount: Scalars['Int']['output'];
};

export type Proposal = {
  __typename?: 'Proposal';
  guid: Scalars['String']['output'];
  pricingResult: PricingResult;
};

export type Query = {
  __typename?: 'Query';
  booking?: Maybe<Booking>;
  bookingLog?: Maybe<BookingLog>;
  getBalanceTransaction?: Maybe<TransactionStatusResponse>;
  getCarriers?: Maybe<Array<Maybe<Carrier>>>;
  getTrackingInfo?: Maybe<Array<Maybe<TrackingInfo>>>;
  merchant?: Maybe<Merchant>;
  requestFTLQuote?: Maybe<Array<FtlQuote>>;
  requestLTLQuote?: Maybe<Array<LtlQuote>>;
  viewer?: Maybe<User>;
};

export type QueryBookingArgs = {
  bookingId: Scalars['String']['input'];
};

export type QueryBookingLogArgs = {
  bookingLogId: Scalars['String']['input'];
};

export type QueryGetBalanceTransactionArgs = {
  txnId: Scalars['String']['input'];
};

export type QueryGetCarriersArgs = {
  scacCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryGetTrackingInfoArgs = {
  loadId: Scalars['String']['input'];
};

export type QueryRequestFtlQuoteArgs = {
  rfq: FtlRfqInput;
};

export type QueryRequestLtlQuoteArgs = {
  rfq: LtlRfqInput;
};

export type Quote = {
  __typename?: 'Quote';
  carrier?: Maybe<LegacyCarrier>;
  cost: Scalars['Float']['output'];
  deliveryDate?: Maybe<Scalars['Date']['output']>;
  transitTime?: Maybe<Scalars['Int']['output']>;
};

export type QuoteAccessorial = {
  __typename?: 'QuoteAccessorial';
  accessorialDescription: Scalars['String']['output'];
  accessorialType: Scalars['String']['output'];
  applyBenchmarkCostBeforeFuel?: Maybe<Scalars['Boolean']['output']>;
  applyCarrierCostBeforeFuel: Scalars['Boolean']['output'];
  applyShipperCostBeforeFuel: Scalars['Boolean']['output'];
  benchmarkAccessorialCost?: Maybe<Scalars['Float']['output']>;
  carrierAccessorialCost: Scalars['Float']['output'];
  shipperAccessorialCost: Scalars['Float']['output'];
};

export type QuoteCarrier = {
  __typename?: 'QuoteCarrier';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  newLiability: Scalars['Float']['output'];
  reliabilityScore: Scalars['Float']['output'];
  usedLiability: Scalars['Float']['output'];
};

export type QuoteInput = {
  baseRate?: InputMaybe<Scalars['Float']['input']>;
  carrier?: InputMaybe<CarrierInput>;
  cost: Scalars['Float']['input'];
  deliveryDate: Scalars['DateTime']['input'];
  ftlAccessorialCharges?: InputMaybe<Array<AccessorialCharge>>;
  guid: Scalars['String']['input'];
  ltlAccessorialCharges?: InputMaybe<Array<AccessorialCharge>>;
  pickupDate?: InputMaybe<Scalars['DateTime']['input']>;
  proposal?: InputMaybe<Scalars['JSON']['input']>;
  shipmentType: Shipment_Type;
  transitTime: Scalars['Int']['input'];
  truckType?: InputMaybe<Truck_Type>;
};

export type QuoteItem = {
  __typename?: 'QuoteItem';
  createdAt: Scalars['DateTime']['output'];
  orderQuote: OrderQuote;
  quantity: Scalars['Int']['output'];
  sku: Sku;
  updatedAt: Scalars['DateTime']['output'];
};

export type Referral = {
  __typename?: 'Referral';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  orderCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  referralCode: ReferralCode;
  referred?: Maybe<User>;
  referrer: User;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  code: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referrer: User;
  shipmentContact?: Maybe<ShipmentContact>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RefetchQuoteInput = {
  orderQuoteId: Scalars['Int']['input'];
  pickupDate: Scalars['String']['input'];
};

export type RefetchQuoteResponse = MutationResponse & {
  __typename?: 'RefetchQuoteResponse';
  error?: Maybe<Scalars['String']['output']>;
  legacyQuote?: Maybe<LegacyQuote>;
};

export type RemoveMerchantResponse = MutationResponse & {
  __typename?: 'RemoveMerchantResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RemoveSkuInput = {
  skuId: Scalars['UUID']['input'];
};

export type RemoveSkuResponse = MutationResponse & {
  __typename?: 'RemoveSkuResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RemoveVendorOrderInput = {
  vendorOrderId: Scalars['UUID']['input'];
};

export type RemoveVendorOrderResponse = MutationResponse & {
  __typename?: 'RemoveVendorOrderResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RemoveWarehouseInput = {
  warehouseId: Scalars['UUID']['input'];
};

export type RemoveWarehouseResponse = MutationResponse & {
  __typename?: 'RemoveWarehouseResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RfqItemInput = {
  dimWeightLbs?: InputMaybe<Scalars['Float']['input']>;
  freightClass: Freight_Class;
  hazardous: Scalars['Boolean']['input'];
  height: Scalars['Float']['input'];
  length: Scalars['Float']['input'];
  packaging: Packaging_Types;
  quantity: Scalars['Int']['input'];
  sizeUoM: Distance_Uom;
  weight: Scalars['Float']['input'];
  weightUoM: Weight_Uom;
  width: Scalars['Float']['input'];
};

export enum Shipment_Type {
  Ftl = 'FTL',
  Ltl = 'LTL',
}

export type ShipmentAddress = {
  __typename?: 'ShipmentAddress';
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  streetAddress1?: Maybe<Scalars['String']['output']>;
};

export type ShipmentContact = {
  __typename?: 'ShipmentContact';
  companyName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasBeenReferred: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<ReferralCode>;
};

export type ShopifyProduct = {
  __typename?: 'ShopifyProduct';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightUnit?: Maybe<Scalars['String']['output']>;
};

export type Sku = {
  __typename?: 'Sku';
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  freightClass: Freight_Class;
  hazmatDetails?: Maybe<HazMatDetails>;
  height: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isHazardous: Scalars['Boolean']['output'];
  leadDay: Scalars['Int']['output'];
  length: Scalars['Int']['output'];
  merchant: Merchant;
  name?: Maybe<Scalars['String']['output']>;
  noFreeze: Scalars['Boolean']['output'];
  packagingType: Packaging_Types;
  sizeUoM: Distance_Uom;
  sku: Scalars['String']['output'];
  stackable: Scalars['Boolean']['output'];
  tariff?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
  weight: Scalars['Int']['output'];
  weightUoM: Weight_Uom;
  width: Scalars['Int']['output'];
};

export type SkuInput = {
  country: Country;
  description: Scalars['String']['input'];
  freightClass: Freight_Class;
  hazardous: Scalars['Boolean']['input'];
  hazmatDetails?: InputMaybe<HazMatDetailsInput>;
  height: Scalars['Int']['input'];
  leadDay: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  packaging: Packaging_Types;
  sizeUoM: Distance_Uom;
  sku: Scalars['String']['input'];
  stackable: Scalars['Boolean']['input'];
  tariff?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
  vendor?: InputMaybe<Scalars['String']['input']>;
  weight: Scalars['Int']['input'];
  weightUoM: Weight_Uom;
  width: Scalars['Int']['input'];
};

export enum Transaction_Type {
  Ftl = 'FTL',
  Ltl = 'LTL',
  Upcharge = 'UPCHARGE',
}

export enum Truck_Type {
  Flatbed = 'flatbed',
  Reefer = 'reefer',
  Van = 'van',
}

export type TrackingInfo = {
  __typename?: 'TrackingInfo';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  departureDate?: Maybe<Scalars['Date']['output']>;
  departureTime?: Maybe<Scalars['String']['output']>;
  freeMessage?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  statusDescription?: Maybe<Scalars['String']['output']>;
  timezoneCode?: Maybe<Scalars['String']['output']>;
  trackingDate?: Maybe<Scalars['Date']['output']>;
  trackingTime?: Maybe<Scalars['String']['output']>;
};

export type TransactionCreateResponse = {
  __typename?: 'TransactionCreateResponse';
  amount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type TransactionDetail = {
  __typename?: 'TransactionDetail';
  bolId: Scalars['String']['output'];
  transaction: TransactionStatusResponse;
  uuid: Scalars['String']['output'];
};

export type TransactionStatusResponse = {
  __typename?: 'TransactionStatusResponse';
  autoPayouts?: Maybe<Scalars['String']['output']>;
  buyer?: Maybe<Buyer>;
  charges?: Maybe<Array<Maybe<BalanceCharge>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  externalReferenceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lines?: Maybe<Array<Maybe<Line>>>;
  notes?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  selectedPaymentMethod?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalDiscount: Scalars['Float']['output'];
  totalLineItems: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  totalShipping: Scalars['Float']['output'];
  totalTax: Scalars['Float']['output'];
};

export type UnassignSkuInput = {
  warehouseSkuId: Scalars['UUID']['input'];
};

export type UnassignSkuResponse = MutationResponse & {
  __typename?: 'UnassignSkuResponse';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateBookingLogInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  logStatus?: InputMaybe<Log_Status>;
  quoteLogUuid?: InputMaybe<Scalars['String']['input']>;
  quotes?: InputMaybe<Scalars['JSON']['input']>;
  rfq?: InputMaybe<Scalars['JSON']['input']>;
  selectedQuote?: InputMaybe<Scalars['JSON']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateBookingLogResponse = MutationResponse & {
  __typename?: 'UpdateBookingLogResponse';
  bookingLog?: Maybe<BookingLog>;
  error?: Maybe<Scalars['String']['output']>;
};

export type UpdateMerchantCarriersInput = {
  scacs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateMerchantCarriersResponse = MutationResponse & {
  __typename?: 'UpdateMerchantCarriersResponse';
  error?: Maybe<Scalars['String']['output']>;
  merchant?: Maybe<Merchant>;
};

export type UpdateShipmentAddressInput = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShipmentAddressResponse = MutationResponse & {
  __typename?: 'UpdateShipmentAddressResponse';
  error?: Maybe<Scalars['String']['output']>;
  updatedAddress?: Maybe<ShipmentAddress>;
};

export type UpdateShipmentContactInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type UpdateShipmentContactResponse = MutationResponse & {
  __typename?: 'UpdateShipmentContactResponse';
  error?: Maybe<Scalars['String']['output']>;
  updatedContact?: Maybe<ShipmentContact>;
};

export type User = {
  __typename?: 'User';
  addressesWithAccessorials?: Maybe<Array<AddressWithAccessorial>>;
  apiKeys: Array<ApiKey>;
  billingAddresses?: Maybe<Array<BillingAddress>>;
  bookingLogs: Array<BookingLog>;
  bookings: Array<Booking>;
  creditBalance: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  featureFlags: Scalars['JSON']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  genericReferralCode: ReferralCode;
  id: Scalars['UUID']['output'];
  insuranceDisabled: Scalars['Boolean']['output'];
  legacyBookings: Array<LegacyBooking>;
  merchant?: Maybe<Merchant>;
  organizationName: Scalars['String']['output'];
  personId: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Contacts that are eligible for referral */
  referrableContacts: Array<ShipmentContact>;
  referralCodes: Array<ReferralCode>;
  referralReceived?: Maybe<Referral>;
  referralsGiven: Array<Referral>;
  shipmentAddresses: Array<ShipmentAddress>;
  shipmentContacts: Array<ShipmentContact>;
  transactions: Array<TransactionDetail>;
  vendor: Scalars['Boolean']['output'];
  vendorOrders: Array<VendorOrder>;
};

export type VendorOrder = {
  __typename?: 'VendorOrder';
  bookingInput: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  ltlQuote: VendorQuote;
  orderNumber: Scalars['String']['output'];
};

export type VendorOrderResponse = {
  __typename?: 'VendorOrderResponse';
  id: Scalars['UUID']['output'];
};

export type VendorQuote = {
  __typename?: 'VendorQuote';
  pickupDate: Scalars['String']['output'];
  quote: LtlQuote;
  rfq: Scalars['JSON']['output'];
};

export enum Weight_Uom {
  Kg = 'kg',
  Lbs = 'lbs',
}

export type Warehouse = {
  __typename?: 'Warehouse';
  addressName?: Maybe<Scalars['String']['output']>;
  addressPrimary: Scalars['String']['output'];
  addressSecondary?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  insidePickup: Scalars['Boolean']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  merchant: Merchant;
  name: Scalars['String']['output'];
  needsLiftgate: Scalars['Boolean']['output'];
  needsReservation: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  stateCode: Scalars['String']['output'];
  timeoffset?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  zipCode: Scalars['String']['output'];
};

export type WarehouseSku = {
  __typename?: 'WarehouseSku';
  activeEnd?: Maybe<Scalars['Date']['output']>;
  activeStart?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  sku: Sku;
  warehouse: Warehouse;
};
